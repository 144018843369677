<template>
  <div v-element_loading="loading">
    <switch-acessar-field
      title="Acessar Billing"
      :disabled="!$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')"
    />
    <div class="ms-2 mt-4 fs-5">
      <b>Gestão do Cliente</b>
    </div>
    <roles-manager
      type="BILLING"
      :clearPermissoes="clearPermissoes"
      subtype="ASSOCIAR_PONTO"
      key="role-manager-ASSOCIAR_PONTO"
      title="Associar Ponto de Medição a Contrato"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="CONFIGURAR_PROINFA"
      :clearPermissoes="clearPermissoes"
      key="role-manager-CONFIGURAR_PROINFA"
      title="Configurar PROINFA de Ponto de Medição"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <div class="ms-2 mt-5 fs-5">
      <b>Faturamento</b>
    </div>
    <roles-manager
      type="BILLING"
      title="Acompanhar Faturamento"
      subtype="ACOMPANHAR_FATURAMENTO"
      :clearPermissoes="clearPermissoes"
      key="role-manager-ACOMPANHAR_FATURAMENTO"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="EXPORTADOR_MONTANTES"
      title="Exportador de Montantes"
      :clearPermissoes="clearPermissoes"
      key="role-manager-EXPORTADOR_MONTANTES"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="EXPORTADOR_ENCARGOS_CCEE"
      title="Exportador de Encargos CCEE"
      :clearPermissoes="clearPermissoes"
      key="role-manager-EXPORTADOR_ENCARGOS_CCEE"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="ACOMPANHAR_CURTO_PRAZO"
      :clearPermissoes="clearPermissoes"
      key="role-manager-ACOMPANHAR_CURTO_PRAZO"
      title="Acompanhamento de Propostas de Curto Prazo"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <div class="ms-2 mt-5 fs-5">
      <b>Gestão do Faturamento</b>
    </div>
    <roles-manager
      type="BILLING"
      :clearPermissoes="clearPermissoes"
      subtype="ASSOCIAR_POS_VENDA"
      title="Associar Pós-Venda"
      key="role-manager-ASSOCIAR_POS_VENDA"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="AJUSTES_PLD_SPREAD"
      title="Ajustes de PLD e Spread"
      :clearPermissoes="clearPermissoes"
      key="role-manager-AJUSTES_PLD_SPREAD"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="IMPORTACAO_LOTE"
      title="Importação em Lote"
      :clearPermissoes="clearPermissoes"
      key="role-manager-IMPORTACAO_LOTE"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      title="Contratos Não Importados"
      :clearPermissoes="clearPermissoes"
      subtype="CONTRATOS_NAO_IMPORTADOS"
      key="role-manager-CONTRATOS_NAO_IMPORTADOS"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <div class="ms-2 mt-5 fs-5">
      <b>Alçadas de Aprovação</b>
    </div>
    <roles-manager
      type="BILLING"
      :clearPermissoes="clearPermissoes"
      subtype="ALCADA_APROVACAO_LIQUIDO"
      title="Alçada para Aprovador de Solicitado Líquido (N1, N2 ou N3)"
      key="role-manager-ALCADA_APROVACAO_LIQUIDO"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      :clearPermissoes="clearPermissoes"
      subtype="ALCADA_APROVACAO_RECOMPRA"
      key="role-manager-ALCADA_APROVACAO_RECOMPRA"
      title="Alçada para Aprovador de Spread de Recompra (N1, N2 ou N3)"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <div class="ms-2 mt-5 fs-5">
      <b>Parametrização</b>
    </div>
    <roles-manager
      type="BILLING"
      subtype="ALCADAS_APROVACAO"
      title="Alçadas de Aprovação"
      :clearPermissoes="clearPermissoes"
      key="role-manager-ALCADAS_APROVACAO"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="CURTO_PRAZO"
      key="role-manager-CURTO_PRAZO"
      :clearPermissoes="clearPermissoes"
      title="Propostas de Curto Prazo"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="ROTINA_IMPORTACAO"
      title="Rotina de Importação"
      :clearPermissoes="clearPermissoes"
      key="role-manager-ROTINA_IMPORTACAO"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <roles-manager
      type="BILLING"
      subtype="BUSCAR_APURACOES"
      title="Botão Importação da CCEE"
      :clearPermissoes="clearPermissoes"
      key="role-manager-BUSCAR_APURACOES_CCEE"
      :disabled="
        !acessar || !$can('CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR')
      "
    />
    <footer>
      <cc-barra-salvar-cancelar
        @save="save"
        @cancel="$router.push({ name: 'BuscaGrupoAcesso' })"
      />
    </footer>
  </div>
</template>
<script>
import { HTTP_RESPONSE } from "@/constants/strings";
import GrupoAcessoModel from "@/models/grupo-acesso/GrupoAcessoModel";
import RolesServices from "@/services/portal/RolesService";
import ComboRolesManager from "../components/RolesComboManager.vue";
import RolesManager from "../components/RolesManager.vue";
import SwitchAcessarField from "../components/SwitchAcessarField.vue";

export default {
  data() {
    return {
      acessar: false,
      firstCheck: false,
      loading: undefined,
      clearPermissoes: false,
      model: GrupoAcessoModel,
    };
  },
  mounted() {
    this.getRole();
  },
  methods: {
    getRole() {
      this.loading = RolesServices.getRoleById(this.$route.params.id)
        .then(({ data }) => {
          this.model.setData(data);
          !this.isCreated ? this.setHeaderText(data.nome) : null;
          this.acessar = this.model.havePermissaoAcessar("BILLING");
          this.firstCheck = !this.model.havePermissaoAcessar("BILLING");
          this.$forceUpdate();
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    save() {
      this.loading = RolesServices.updatePermissions(this.model.getData())
        .then(() => {
          this.mxToggleToast(HTTP_RESPONSE.GRUPO_ACESSO.SUCCESS.UPDATE);
          this.getRole();
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
  },
  watch: {
    acessar() {
      if (!this.acessar) {
        this.model.changePermissaoAcesso("BILLING", false);
        this.model.clearPermissoes("BILLING");
        this.clearPermissoes = true;
      } else {
        if (this.firstCheck) {
          this.model.clearPermissoes("BILLING");
          this.clearPermissoes = true;
          this.firstCheck = false;
        }
        this.model.changePermissaoAcesso("BILLING", true);
      }
    },
  },
  components: {
    RolesManager,
    ComboRolesManager,
    SwitchAcessarField,
  },
};
</script>
