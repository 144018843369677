class GrupoAcessoModel {
  constructor(data = new Object()) {
    this._id = data.id;
    this._nome = data.nome;
    this._ativo = data.ativo;
    this._usuarios = data.usuarios;
    this._permissoes = data.permissoes;
  }

  set id(id = null) {
    this._id = id;
  }

  get id() {
    return this._id ?? null;
  }

  set nome(nome = null) {
    this._nome = nome;
  }

  get nome() {
    return this._nome ?? null;
  }

  set ativo(ativo = true) {
    this._ativo = ativo;
  }

  get ativo() {
    return this._ativo ?? true;
  }

  set usuarios(usuarios = null) {
    this._usuarios = usuarios;
  }

  get usuarios() {
    return this._usuarios ?? new Array();
  }

  set permissoes(permissoes = null) {
    this._permissoes = permissoes;
  }

  get permissoes() {
    return this._permissoes ?? null;
  }

  clearPermissoes(optionType = null) {
    if (optionType === "GERACAO_PROPOSTAS") {
      this.options[optionType].forEach((option) => {
        if (option.permissao === "ACESSAR_GERACAO_DESABILITADO") {
          option.enabled = true;
        } else {
          option.enabled = false;
        }
      });
    } else if (optionType === "BILLING" || optionType === "CENTRAL_CADASTROS") {
      Object.keys(this.options[optionType]).forEach((type) => {
        this.options[optionType][type].map((option) => {
          if (
            option.permissao === "VISUALIZAR_FATURAMENTO" ||
            option.nome === "Não Habilitar Funcionalidade"
          ) {
            option.enabled = true;
          } else {
            option.enabled = false;
          }
        });
      });
    } else {
      Object.keys(this.options).forEach((type) => {
        if (type === "BILLING" || type === "CENTRAL_CADASTROS") {
          Object.keys(this.options[type]).forEach((subtype) => {
            this.options[type][subtype].map((option) => {
              option.enabled = false;
            });
          });
        } else {
          this.options[type].map((option) => (option.enabled = false));
        }
      });
    }
  }

  resetData() {
    this._id = null;
    this._nome = null;
    this._ativo = true;
    this._usuarios = null;
    this._permissoes = null;

    Object.keys(this.options).forEach((type) => {
      if (type === "BILLING" || type === "CENTRAL_CADASTROS") {
        Object.keys(this.options[type]).forEach((subtype) => {
          this.options[type][subtype].map((option) => {
            option.enabled = false;
          });
        });
      } else {
        this.options[type].map((option) => (option.enabled = false));
      }
    });
  }

  getOptions() {
    const { options } = this;

    Object.keys(options).forEach((type) => {
      if (type === "BILLING" || type === "CENTRAL_CADASTROS") {
        Object.keys(options[type]).forEach((subtype) => {
          options[type][subtype].map((option) => {
            option.enabled =
              this.permissoes.filter((p) => p.permissao == option.permissao)
                .length > 0;
          });
        });
      } else {
        options[type].map(
          (option) =>
            (option.enabled =
              this.permissoes.filter((p) => p.permissao == option.permissao)
                .length > 0)
        );
      }
    });

    return options;
  }

  setData(data = new Object()) {
    this._id = data.id;
    this._nome = data.nome;
    this._ativo = data.ativo;
    this._usuarios = data.usuarios;

    const permissions = new Array();

    data.permissoes.forEach((permissao) => {
      if (permissao.permissao === "ACESSAR_CENTRAL_CADASTROS")
        this.changePermissaoAcesso("CENTRAL_CADASTROS", true);
      else if (permissao.permissao === "ACESSAR_GERACAO_PROPOSTAS")
        this.changePermissaoAcesso("GERACAO_PROPOSTAS", true);
      else if (permissao.permissao === "ACESSAR_BILLING")
        this.changePermissaoAcesso("BILLING", true);
    });

    Object.keys(this.options).map((type) => {
      if (type === "BILLING" || type === "CENTRAL_CADASTROS") {
        Object.keys(this.options[type]).map((subtype) => {
          this.options[type][subtype].map((option) => {
            data.permissoes.map((permissao) => {
              if (permissao.permissao === option.permissao) {
                permissions.push(option);
              }
            });
          });
        });
      } else {
        this.options[type].map((option) => {
          data.permissoes.map((permissao) => {
            if (permissao.permissao === option.permissao) {
              permissions.push(option);
            }
          });
        });
      }
    });

    this._permissoes = permissions;
  }

  changePermissaoAcesso(type, value) {
    const { options } = this;
    const permissaoDescription = "ACESSAR_" + type;
    const acesso = options["ACESSOS"].find(
      (acesso) => acesso.permissao === permissaoDescription
    );
    if (acesso) acesso.enabled = value;
  }

  havePermissaoAcessar(type) {
    const { options } = this;
    const permissaoDescription = "ACESSAR_" + type;
    const acesso = options["ACESSOS"].find(
      (acesso) => acesso.permissao === permissaoDescription && acesso.enabled
    );
    return !!acesso;
  }

  getData(acessar = null) {
    const { id, nome, ativo, usuarios, options } = this;

    const permissoes = new Array();

    Object.keys(options).map((type) => {
      if (type === "BILLING" || type === "CENTRAL_CADASTROS") {
        Object.keys(options[type]).map((subtype) => {
          options[type][subtype].map((option) => {
            if (option.enabled) {
              permissoes.push(option);
            }
          });
          return options[type][subtype];
        });
      } else {
        options[type].map((option) => {
          if (option.enabled) {
            permissoes.push(option);
          }
        });
      }
      return options[type];
    });

    options["ACESSOS"].map((acesso) => {
      if (acesso.enabled) {
        permissoes.push(acesso);
      }
    });

    return {
      id,
      nome,
      ativo,
      usuarios,
      permissoes,
    };
  }

  comboChange(combo, selected) {
    this.options.forEach((option) => {
      if (option.combo == combo && option.permissao == selected) {
        option.enabled = true;
        this.married(option);
      }
      if (option.combo == combo && option.permissao != selected) {
        option.enabled = false;
        if (option.trigger && option.trigger.length > 0) {
          option.trigger.forEach((flag) => {
            this.options[
              this.options.findIndex((g) => g.permissao == flag)
            ].enabled = false;
          });
        }
      }
    });
  }

  married(data) {
    if (data.trigger && data.trigger.length > 0) {
      data.trigger.forEach((flag) => {
        this.options[
          this.options.findIndex((g) => g.permissao == flag)
        ].enabled = data.enabled;
      });
    }
  }

  options = {
    ACESSOS: [
      {
        id: null,
        tipo: "CENTRAL_CADASTROS",
        permissao: "ACESSAR_CENTRAL_CADASTROS",
        nome: "Acessar Central de Cadastros",
        enabled: false,
      },
      {
        id: null,
        tipo: "GERACAO_PROPOSTAS",
        permissao: "ACESSAR_GERACAO_PROPOSTAS",
        nome: "Acessar Enviador de Propostas",
        enabled: false,
      },
      {
        id: null,
        tipo: "BILLING",
        permissao: "ACESSAR_BILLING",
        nome: "Acessar Billing",
        enabled: false,
      },
    ],
    CENTRAL_CADASTROS: {
      USUARIOS: [
        {
          id: null,
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_USUARIO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_USUARIO_VISUALIZAR",
          nome: "Somente Visualiza (Consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_USUARIO_VISUALIZAR_EDITAR",
          nome: "Visualizar e Editar",
          enabled: false,
        },
      ],
      GRUPOS_ACESSO: [
        {
          id: null,
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_GRUPO_ACESSO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR",
          nome: "Somente Visualiza (Consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_GRUPO_ACESSO_VISUALIZAR_EDITAR",
          nome: "Visualizar e Editar",
          enabled: false,
        },
      ],
      PERMISSOES_GERAIS: [
        {
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
        },
        {
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_CONSULTAR",
          nome: "Consulta Cadastro",
        },
        {
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_EDITAR",
          nome: "Consulta e Edita Cadastro",
        },
        {
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_APROVAR",
          nome: "Consulta, Edita e Aprova Cadastro",
        },
        {
          tipo: "CENTRAL_CADASTROS",
          permissao: "CENTRAL_CADASTROS_PARAMETRIZAR",
          nome: "Consulta, Edita, Aprova Cadastro e Parametrizações",
        },
      ],
    },
    GERACAO_PROPOSTAS: [
      {
        id: null,
        tipo: "GERACAO_PROPOSTAS",
        permissao: "ACESSAR_GERACAO_DESABILITADO",
        nome: "Não Habilitar Funcionalidade",
        enabled: false,
      },
      {
        id: null,
        tipo: "GERACAO_PROPOSTAS",
        permissao: "GERACAO_PROPOSTAS_EDITAR",
        nome: "Consulta, cria e edita",
        enabled: false,
      },
      {
        id: null,
        tipo: "GERACAO_PROPOSTAS",
        permissao: "GERACAO_PROPOSTAS_PARAMETRIZAR",
        nome: "Consulta, cria, edita e parametriza",
        enabled: false,
      },
    ],
    BILLING: {
      ASSOCIAR_PONTO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "ASSOCIAR_PONTO_AO_CONTRATO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR",
          nome: "Visualiza e edita",
          enabled: false,
        },
      ],
      CONFIGURAR_PROINFA: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "CONFIGURAR_PROINFA_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "CONFIGURAR_PROINFA_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "CONFIGURAR_PROINFA_VISUALIZAR_EDITAR",
          nome: "Visualiza e edita",
          enabled: false,
        },
      ],
      ACOMPANHAR_FATURAMENTO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "VISUALIZAR_FATURAMENTO",
          nome: "Visualizar faturamento",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "REALIZAR_FATURAMENTO",
          nome: "Visualizar e realizar faturamento",
          enabled: false,
        },
      ],
      IMPORTACAO_LOTE: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "IMPORTACAO_LOTE_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "IMPORTACAO_LOTE_HABILITADO",
          nome: "Habilitar funcionalidade",
          enabled: false,
        },
      ],
      CONTRATOS_NAO_IMPORTADOS: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "CONTRATOS_NAO_IMPORTADOS_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "CONTRATOS_NAO_IMPORTADOS_HABILITADO",
          nome: "Habilitar funcionalidade",
          enabled: false,
        },
      ],
      FECHAR_FATURAMENTO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "FECHAR_FATURAMENTO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "FECHAR_FATURAMENTO_HABILITADO",
          nome: "Habilitar funcionalidade",
          enabled: false,
        },
      ],
      ALCADA_APROVACAO_LIQUIDO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_LIQUIDO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N1",
          nome: "Habilitar alçada de Aprovador Nível 1",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N2",
          nome: "Habilitar alçada de Aprovador Nível 2",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_LIQUIDO_APROVADOR_N3",
          nome: "Habilitar alçada de Aprovador Nível 3",
          enabled: false,
        },
      ],
      ALCADA_APROVACAO_RECOMPRA: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_RECOMPRA_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N1",
          nome: "Habilitar alçada de Aprovador Nível 1",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N2",
          nome: "Habilitar alçada de Aprovador Nível 2",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADA_APROVACAO_RECOMPRA_APROVADOR_N3",
          nome: "Habilitar alçada de Aprovador Nível 3",
          enabled: false,
        },
      ],
      EXPORTADOR_MONTANTES: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "EXPORTADOR_MONTANTES_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "EXPORTADOR_MONTANTES_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR",
          nome: "Visualiza e exporta montantes",
          enabled: false,
        },
      ],
      EXPORTADOR_ENCARGOS_CCEE: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "EXPORTADOR_ENCARGOS_CCEE_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "EXPORTADOR_ENCARGOS_CCEE_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "EXPORTADOR_ENCARGOS_CCEE_VISUALIZAR_EDITAR",
          nome: "Visualiza e exporta encargos CCEE",
          enabled: false,
        },
      ],
      ACOMPANHAR_CURTO_PRAZO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "ACOMPANHAR_CURTO_PRAZO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ACOMPANHAR_CURTO_PRAZO_VISUALIZAR_SINALIZAR",
          nome: "Visualiza e sinaliza boletagem manual em WBC",
          enabled: false,
        },
      ],
      ASSOCIAR_POS_VENDA: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "ASSOCIAR_POS_VENDA_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ASSOCIAR_POS_VENDA_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR",
          nome: "Visualiza e associa pós-venda",
          enabled: false,
        },
      ],
      AJUSTES_PLD_SPREAD: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "AJUSTES_PLD_SPREAD_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "AJUSTES_PLD_SPREAD_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR",
          nome: "Visualiza e edita",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR",
          nome: "Visualiza, edita e aprova",
          enabled: false,
        },
      ],
      ALCADAS_APROVACAO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADAS_APROVACAO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADAS_APROVACAO_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ALCADAS_APROVACAO_VISUALIZAR_EDITAR",
          nome: "Visualiza e edita",
          enabled: false,
        },
      ],
      CURTO_PRAZO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "CURTO_PRAZO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "CURTO_PRAZO_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "CURTO_PRAZO_VISUALIZAR_EDITAR",
          nome: "Visualiza e edita",
          enabled: false,
        },
      ],
      ROTINA_IMPORTACAO: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "ROTINA_IMPORTACAO_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ROTINA_IMPORTACAO_VISUALIZAR",
          nome: "Somente visualiza (consulta)",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
          nome: "Visualiza e Liga/Desliga",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR",
          nome: "Visualiza, Liga/Desliga e Edita",
          enabled: false,
        },
      ],
      BUSCAR_APURACOES: [
        {
          id: null,
          tipo: "BILLING",
          permissao: "BUSCAR_APURACOES_CCEE_DESABILITADO",
          nome: "Não Habilitar Funcionalidade",
          enabled: false,
        },
        {
          id: null,
          tipo: "BILLING",
          permissao: "BUSCAR_APURACOES_CCEE",
          nome: 'Exibir botão "Iniciar Importação CCEE"',
          enabled: false,
        },
      ],
    },
  };
}

export default new GrupoAcessoModel();
